<template>
  <div>
    <!-- 如果是社会单位加样式society-bg -->
    <div class="company-bg">
      <div class="container">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/home">首页</a></li>
            <li class="breadcrumb-item">
              <a href="/expertlist">安全文化专家名录</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">详情</li>
          </ol>
        </nav>
        <div class="expert-card">
          <img class="avatar" :src="info.avatar" />
          <div class="expert-intro">
            <span class="expert-name">{{info.real_name}}</span>
            <span class="expert-job">{{info.gender=='1'?'男':'女'}} {{info.tag}}</span>
          </div>
          <div class="expert-shape"></div>
          <img src="../../assets/company/icon-expert.png" class="icon-expet" />
        </div>
        <div class="panel">
          <div class="panel-title">专家简介</div>
          <div class="rich-text">
            {{decodeContent(info.intro)}}
          </div>
        </div>
        <div class="panel">
          <div class="panel-title">文化服务</div>
          <div class="rich-text">
            {{decodeContent(info.services)}}
          </div>
        </div>
        <!-- 公共收藏 -->
        <div :class="['fav', info.is_favourite==1?'active':'']" @click="toFavourite">
          <div class="fav-shape">
            <i class="iconfont icon-favourite_normal"></i>
          </div>
          收藏
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {
    expertFavourite,
    expertDetail
  } from '@/network/API.js'
  export default {
    data() {
      return {
        info: {},
      }
    },
    async created() {
      if (this.$route.params.id) {
        this.info = await expertDetail(this.$route.params.id)
      }
    },
    methods: {
      async toFavourite() {
        let res = expertFavourite({
          id: this.info.expert_id,
          code: "expert",
        })
        this.info.is_favourite = res.is_favourite
        if (this.info.is_favourite == 1) {
          this.$toast("收藏成功");
        } else {
          this.$toast("取消收藏");
        }
      }
    }
  }
</script>
<style scoped>
  .company-bg {
    padding-top: 30px;
  }

  .breadcrumb-item.active,
  .breadcrumb-item a,
  .breadcrumb-item+.breadcrumb-item::before {
    color: #fff;
  }

  .panel {
    position: relative;
    z-index: 2;
    padding: 30px;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
    background: linear-gradient(0deg,
        rgba(255, 255, 255, 0.42) 0%,
        rgba(255, 255, 255, 0.4) 0%,
        #ffffff 99%);
    border-radius: 10px;
    margin-bottom: 30px;
  }

  .panel-title {
    font-size: 24px;
    font-weight: 600;
    color: #3b75ea;
    margin-bottom: 30px;
  }

  .expert-card {
    position: relative;
    display: flex;
    width: 100%;
    padding: 30px 0;
    align-items: center;
    margin-bottom: 30px;
    border-radius: 20px;
    margin: 0;
    background: none;
    box-shadow: none;
    overflow: visible
  }

  .expert-shape {
    position: fixed;
    z-index: 1;
  }

  .expert-name,
  .expert-job {
    color: #fff;
    margin-bottom: 10px;
  }

  .expert-name {
    font-size: 30px;
  }

  .icon-expet {
    top: -100px;
    opacity: 0.4;
  }

  .expert-card .avatar {
    width: 150px;
    height: 150px;
  }
</style>